class AboutUsPageCustom extends AboutUsPage {
    getTemplate() {
        return `<div class="static-page about-us-page">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="section-content container">
                        <div class="section-header text-center">
                            <img src="/static/ecommerce/img/aboutus_banner.jpg" class="img-fluid" alt="" />
                            <div class="float-right mt-3">
                                <p class="text-center mb-0">Gustavo y Vladimir Aguzin</p>
                                <strong class="text-center">Fundadores</strong>
                            </div>
                        </div>
                        <div class="row mt-5 mb-4" style="clear: both">
                            <div id="historia" class="col">
                                <div>
                                    <h4 class="section-title">Historia</h4>
                                    <p>Nuestras vidas están marcadas por el servicio, por cubrir necesidades que no están cubiertas o por mejorar aquellas necesidades hoy 
                                    cubiertas, pero insatisfactoriamente.</p>
                                    <p>Nuestra pasión por la mejora continua, el binestar, la innovación y el desarrollo, nos llevo a trasladar todo lo aprendido durante 
                                    más de 20 años como emprendedores  y empresarios del mundo B2B (Business to Business) al mundo del consumidor final.</p>
                                    <p>Es así como fuimos siempre buscando un mejor producto y servicio para las empresas que atendíamos, pero eso no fue suficiente. Queríamos 
                                    darles ese mismo servicio y beneficio a sus colaboradores. Fue así como fuimos trabajando muy cerca de ellos dentro de las empresas con el fin 
                                    de satisfacer sus necesidades y hacerlos más felices en su lugar de trabajo. Esta tarea siempre nos apasiono, porque al momento de desarrollar 
                                    un nuevo servicio para estos consumidores, ellos se apasionaban aún más cuando lo recibían. Siempre nos importo investigar, conocer e implementar 
                                    conceptos para ayudar a las empresas a que sus colaboradores se sientan mejor. Así nació Ofisnack. Un concepto para mejorar el clima laboral, 
                                    dentro y fuera de la oficina, incrementando la motivación, la retención y la productividad de los colaboradores y haciendo a las empresas un lugar 
                                    más feliz donde se pueda trabajar.</p>
                                    <p>Fue tan bueno el feedback de las personas dentro de las empresas, que decidimos brindar esa misma experiencia a sus familiares, amigos, conocidos 
                                    y toda la comunidad en sí. Hoy cualquier persona puede comprar el snack saludable que desee, el cual ha sido cuidadosamente seleccionado por nuestros 
                                    expertos, quienes no solo observan sus propiedades, sino que también estudian el proceso y lugar de fabricación y el origen del productor. Ofisnack 
                                    es el resultado de buenas experiencias de usuarios y de nuestra continua preocupación para que a sociedad consuma con mayor frecuencia productos 
                                    sanos, naturales y sustentables, y de esta manera convertirlo en un estilo de vida.</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 text-center text-md-right">
                                <p class="quote mb-3 text-left">
                                <span><img src="/static/ecommerce/img/cuote-open-green.png" width="38" class="img-fluid" alt="" /></span>
                                    Ofisnack, un concepto para mejorar el clima laboral, dentro y fuera de la oficina
                                    <span><img src="/static/ecommerce/img/cuote-close-green.png" width="38" class="img-fluid" alt="" /></span>
                                </p>
                                <img src="/static/ecommerce/img/aboutus_img_lateral.jpeg" class="img-fluid mb-3" alt="" />
                                <img src="/static/ecommerce/img/aboutus_img_lateral2.jpeg" class="img-fluid" alt="" />
                            </div>
                        </div>
                        <hr>
                        <div class="row my-5">
                            <div id="mision" class="col">
                                <div>
                                    <h4 class="section-title">Misión</h4>
                                    <p>Ser una empresa integra, honesta y volcada cien por ciento a la provisión de snacks y productos saludables, naturales y sustentables para los negocios, 
                                    empresas, sus colaboradores y el consumidor en general.</p>
                                    <p class="highlight">Buscamos desarrollar la mejor experiencia de compra, agregando valor a los productos y servicios que comercializamos.</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 text-center text-md-right">
                                <img src="/static/ecommerce/img/aboutus_img_lateral3.jpeg" class="img-fluid" alt="" />
                            </div>
                        </div>
                        <hr>
                        <div class="row my-5">
                            <div id="proposito" class="col-12">
                                <h4 class="section-title">Propósito</h4>
                                <p>Ser una empresa que busca trabajar con emprendedores enfocados en desarrollar productos saludables, naturales y sustentables. Productores que prioricen 
                                la calidad a través de la innovación. Valoramos el impacto que sus empresas producen en la comunidad y en el medio ambiente. Seleccionamos cada proveedor 
                                escuchando su historia de vida, sus sueños y sus objetivos como emprendedor.</p>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 text-center">
                                <img src="/static/ecommerce/img/aboutus_img_inferior.jpeg" class="img-fluid fixed-height" alt="" />
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 text-center mt-2 mt-md-0">
                                <img src="/static/ecommerce/img/aboutus_img_inferior2.jpeg" class="img-fluid fixed-height" alt="" />
                            </div>
                            <div class="col-12 col-lg-4 text-center mt-2 mt-lg-0">
                                <img src="/static/ecommerce/img/aboutus_img_inferior3.jpeg" class="img-fluid fixed-height" alt="" />
                            </div>
                            <div class="col-12">
                                <p class="highlight-blue mt-3 pr-5">Nuestro propósito es generar un cambio en la sociedad, a través de las personas con las que trabajamos.</p>
                            </div>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

AboutUsPageCustom.registerComponent();